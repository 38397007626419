import React from 'react';
import { Button, Card, Col, Container, Row, Image } from 'react-bootstrap';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import BoltIcon from '@mui/icons-material/Bolt';
import RouteIcon from '@mui/icons-material/Route';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { FlashSummaryDesktop, OnlineFlow, MainPicLanding } from '../../assets/images';
import '../../locales/i18n';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import ConactForm from '../../components/contact';


function HomePage() {
	const { t } = useTranslation();
	return (
		<>
			<section className='section-hero'>
				<div className='bg'></div>
				<Container fluid>
					<div className='baaner_back banner_row'>
						<div className='main banner_left'>
							<div className='banner_content'>
								<div className='banner_box'>
									<div className='mainheader'>
										<h1>{t('Home.Title')}</h1>
									</div>
									<div className='mainfeature'>
										<h5>{t('Home.Features.F0')}</h5>
										{/*
											<ul>
												<li>{t('Home.Features.F1')}</li>
												<li>{t('Home.Features.F2')}</li>
												<li>{t('Home.Features.F3')}</li>
												<li>{t('Home.Features.F4')}</li>
											</ul>
											*/}
									</div>
									<div className='bookingbuton'>
										<Button className=" scal rounded-pill bg-black text-white px-4 py-3 btn-h banner-btn" href="#contact-from">{t('Home.Button.BookDemo')}<i className="ms-2 bi bi-arrow-right"></i></Button>
									</div>
								</div>
							</div>
						</div>
						{
							<div className='banner_right illustrations has-background-illustration'>
								<Image src={MainPicLanding} fluid />
							</div>
						}
					</div>
				</Container>
			</section>

			<section className='product-section'>
				<Container>
					<Row className='text-center product-title'>
						<h3 className="heading-title mb-0">
							{t('Home.CardGrid.Title')}
						</h3>
						<h4 className='mb-0 text-black pt-30 pb-5'>
							{t('Home.CardGrid.SubTitle')}
						</h4>
					</Row>
					<Row xs={1} md={2} className="g-4 product-bg mt-50 align-center">
						<Col className=''>
							<div className='product-image'>
								<Image src={FlashSummaryDesktop} />
							</div>
						</Col>
						<Col>
							<Card.Body className='p-0 wrap-box card-content'>
								<Card.Title className='mb-0 '>
									<h2 className='mb-0'>
										{t('Home.CardGrid.Cards.Title1')}
									</h2>
								</Card.Title>
								<Card.Text className='text-black-50 mb-0'>
									{t('Home.CardGrid.Cards.Description1')}
								</Card.Text>
							</Card.Body>
						</Col>
					</Row>
					<Row xs={1} md={2} className="g-4 product-bg mt-30 align-center text-right flex flex-xl-row flex-md-row flex-column-reverse ">
						<Col className=''>
							<Card.Body className='p-0 wrap-box card-content'>
								<Card.Title className='mb-0'>
									<h2 className='mb-0'>
										{t('Home.CardGrid.Cards.Title2')}
									</h2>
								</Card.Title>
								<Card.Text className='text-black-50 mb-0'>
									{t('Home.CardGrid.Cards.Description2')}
								</Card.Text>
							</Card.Body>
						</Col>
						<Col className=''>
							<div className='product-image'>
								<Image src={OnlineFlow} />
							</div>
						</Col>
					</Row>

				</Container>
			</section>

			<ConactForm />

			<section>
				<Container>
					<div className='blocks_name'>
						<h2>
							{t('Home.BlockCardGrid.Title')}
						</h2>
						<p>
							{t('Home.BlockCardGrid.SubTitle')}
						</p>
					</div>

					<div className='block_list'>

						<div className='block_model'>
							<Row>
								<Col md={4} className='p-0'>
									<div className='col_img'>
										<ForwardToInboxIcon style={{ width: '80%', height: '80%', color: '#5C5BE6' }} />
									</div>
								</Col>
								<Col md={8} className='p-0'>
									<div className='box_content_wrapp'>
										<h2>
											{t('Home.BlockCardGrid.blockCards.Title1')}
										</h2>
										<p>{t('Home.BlockCardGrid.blockCards.Description1')}</p>
									</div>
								</Col>
							</Row>
						</div>
						<div className='block_model'>
							<Row>
								<Col md={4} className='p-0'>
									<div className='col_img'>
										<BoltIcon style={{ width: '80%', height: '80%', color: '#5C5BE6' }} />
									</div>
								</Col>
								<Col md={8} className='p-0'>
									<div className='box_content_wrapp'>
										<h2>{t('Home.BlockCardGrid.blockCards.Title2')}</h2>
										<p>{t('Home.BlockCardGrid.blockCards.Description2')}</p>
									</div>
								</Col>
							</Row>
						</div>
						<div className='block_model'>
							<Row>
								<Col md={4} className='p-0'>
									<div className='col_img'>
										<ManageSearchIcon style={{ width: '80%', height: '80%', color: '#5C5BE6' }} />
									</div>
								</Col>
								<Col md={8} className='p-0'>
									<div className='box_content_wrapp'>
										<h2>{t('Home.BlockCardGrid.blockCards.Title3')}</h2>
										<p>{t('Home.BlockCardGrid.blockCards.Description3')}</p>
									</div>
								</Col>
							</Row>
						</div>

						<div className='block_model'>
							<Row>
								<Col md={4} className='p-0'>
									<div className='col_img'>
										<RouteIcon style={{ width: '80%', height: '80%', color: '#5C5BE6' }} />
									</div>
								</Col>
								<Col md={8} className='p-0'>
									<div className='box_content_wrapp'>
										<h2>{t('Home.BlockCardGrid.blockCards.Title4')}</h2>
										<p>{t('Home.BlockCardGrid.blockCards.Description4')}</p>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Container>

			</section>

			<section className='p-top section_faqs'>
				<Container>
					<Row>
						<Col md={5}>
							<div className="faqs_title">{t('Home.Faqs.Title')}</div>
						</Col>
						<Col md={7}>
							<Accordion className='faqs_section'>
								<Accordion.Item eventKey="0" className='border-none'>
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question1')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans1')}
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question2')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans2')}
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question3')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans3')}
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question4')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans4')}
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question5')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans5')}
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="5">
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question6')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans6')}
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="6">
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question7')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans7')}
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="7">
									<Accordion.Header>{t('Home.Faqs.Faqscontent.question8')}</Accordion.Header>
									<Accordion.Body>
										{t('Home.Faqs.Faqscontent.Ans8')}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
}
export default HomePage;
