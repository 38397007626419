import React, { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { RightTick } from '../../assets/images';
function ConactForm() {
	const formRef = useRef(null);
	const [showMessage, setShowMessage] = useState(false);
	const { t } = useTranslation();
	const [contactForm, setContactForm] = useState({
		'email': '',
		'address': '',
		'phone_number': '',
		'company_name': ''
	});
	const [validated, setValidated] = useState(false);
	const [apiError, setApiError] = useState('');
	const handleChange = (e) => {
		setContactForm({ ...contactForm, [e.target.name]: e.target.value });
	};
	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			setValidated(false);
			setApiError('');
			setContactForm({
				'email': '',
				'address': '',
				'phone_number': '',
				'company_name': ''
			});
		}
	};
	const handleSubmit = async(event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			event.preventDefault();
			setShowMessage(false);
			const response = await fetch(process.env.REACT_APP_API_URL + 'contact-form', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(contactForm)
			});

			if (response.ok) {
				setShowMessage(true);
				handleReset();
			} else {
				const data = await response.json();
				setApiError(data.message.email[0]);
			}
		}
	};
	const handleEmailChange = (e) => {
		setContactForm({ ...contactForm, [e.target.name]: e.target.value });
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		if (e.target.value == '' || emailRegex.test(e.target.value)) {
			setApiError('');
		} else {
			setApiError('Invalid Email Address');
		}
	};
	return (
		<section className='mt-0 contact-from' id='contact-from'>
			<Container>
				<div className='text-center bg-black text-white rounded-4 py-5 custom-border'>
					<Row className='fx-row'>
						<h1 className='mb-0'>
							{t('Home.ContactForm.Title')}
						</h1>
						<h6 className='fw-normal'>
							{t('Home.ContactForm.SubTitle')}
						</h6>
					</Row>
					<Row className='d-flex justify-content-center from'>
						<Col className='p-0'>
							<Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit} autoComplete='off'>
								<Stack gap={3}>
									<Form.Floating>
										<Form.Control id='email' name='email' type="text" size='lg' placeholder={`${t('Home.ContactForm.Email')}*`} className='input-filed rounded-4' isInvalid={apiError} onChange={handleEmailChange} required />
										<Form.Control.Feedback type='invalid'>
											{apiError || 'Please provide a valid email.'}
										</Form.Control.Feedback>
										<label htmlFor="email">{`${t('Home.ContactForm.Email')}*`}</label>
									</Form.Floating>
									<Form.Floating>
										<Form.Control id='address' name='address' type='text' size='lg' placeholder={t('Home.ContactForm.Address')} className='input-filed rounded-4' onChange={handleChange} required />
										<label htmlFor="address">{`${t('Home.ContactForm.Address')}*`}</label>
									</Form.Floating>
									<Form.Floating>
										<Form.Control id='phone_number' name='phone_number' type='tel' size='lg' placeholder={t('Home.ContactForm.PhoneNumber')} className='input-filed rounded-4' onChange={handleChange} required />
										<label htmlFor="phone_number">{`${t('Home.ContactForm.PhoneNumber')}*`}</label>
									</Form.Floating>
									<Form.Floating>
										<Form.Control id='company_name' name='company_name' type='text' size='lg' placeholder={t('Home.ContactForm.Name')} className=' input-filed rounded-4' onChange={handleChange} required />
										<label htmlFor="company_name">{`${t('Home.ContactForm.Name')}*`}</label>
									</Form.Floating>
									<Row>
										<Col>
											<Button size='lg' type='submit' className='scal font-16 rounded-pill bg-white text-black px-4 py-4 border-0'>{t('Home.ContactForm.SubmitButton')}</Button>
										</Col>
									</Row>

								</Stack>
							</Form>
							{showMessage &&
								<>
									<div className='msg_box'>
										<RightTick />
										<Typography className='error_msg' color="initial">
											{t('Home.ContactForm.ErrorMsg')}
											<br></br>
										</Typography>
									</div>
									<Typography className='error_msg_wrap' color="initial">	{t('Home.ContactForm.ErrorSubMsg')}</Typography>
								</>
							}
						</Col>
					</Row>
				</div>
			</Container>
		</section>);
}
export default ConactForm;