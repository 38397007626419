import React from 'react';
import Container from 'react-bootstrap/Container';
import { Logo } from '../../assets/images/index';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
function Footer() {
	const { t } = useTranslation();
	return (
		<footer className='p-0 border'>
			<Container>
				<Row className='footer'>
					<Col className='py-2' md={4} sm={12}>
						<div className='logowrapper'>
							<Logo />
						</div>
					</Col>
					<Col className='p-0 width-100 ma-top' md={4} sm={12}>
						<Stack direction="horizontal" gap={3}>
							{/*
							<div className='list'>
								<a href="cookies/" className='text-black-50 text-decoration-none'>
									{t('Home.Footer.CookiePolicy')}
								</a>
							</div>
							*/}
							<div className='list'>
								<a href="/terms-conditions/" target="_blank" rel="noopener noreferrer" className='text-black-50 text-decoration-none'>
									{t('Home.Footer.UseTermService')}
								</a>
							</div>
							<div className='list'>
								<a href="/privacy-policy/" target="_blank" rel="noopener noreferrer" className='text-black-50 text-decoration-none'>
									{t('Home.Footer.PrivacyPolicy')}
								</a>
							</div>
						</Stack>
					</Col>
					<Col className='py-2 width-100' md={4} sm={12}>
						<div className='float-right text-black-50'>
							Copyright 2024 Quotabus SRL
							<br />
							<a href='mailto:info@quotabus.com?subject=Richiesta informazioni'>info@quotabus.com</a>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}
export default Footer;