import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import '../../locales/i18n';


const LayoutComponent = ({ children }) => {
	return (
		<>
			<Header />
			{ children }
			<Footer />
		</>
	);
};
export default LayoutComponent;
