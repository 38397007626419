import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {
	//NavDropdown,
	Offcanvas
} from 'react-bootstrap';
import { Logo } from '../../assets/images';
//import i18n from '../../locales/i18n';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';


function Header() {
	const { t } = useTranslation();
	const location = useLocation();
	{/*
	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};
	*/}
	return (
		<Navbar key={'lg'} expand={'lg'} className='header rounded-pill px-3 py-3'>
			<Container>
				<Navbar.Brand href="/">
					<div className='logowrapper'>
						<Logo />
					</div>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls={`responsive-navbar-nav offcanvasNavbar-expand-${'lg'}`} />
				<Navbar.Offcanvas
					id={`offcanvasNavbar-expand-${'lg'}`}
					aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
					placement="end"
				>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
							<Navbar.Brand href="#">
								<div className='logowrapper'>
									<Logo />
								</div>
							</Navbar.Brand>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="me-auto">

							</Nav>
							<Nav className='gap-3'>
								{/*
								<NavDropdown title={(i18n.language.toUpperCase())} className='secondry-btn border border rounded-pill'>
									<NavDropdown.Item href="#" onClick={() => changeLanguage('it')}>
                                IT
									</NavDropdown.Item>
									<NavDropdown.Item href="#" onClick={() => changeLanguage('en')}>
                                EN
									</NavDropdown.Item>
								</NavDropdown>
								*/}
								<aside className="aside">
									<Nav.Link target='_blank' href="https://partner.quotabus.com/" className='hover-btn primary-btn border border rounded-pill p-4 header-btn'>
										{t('Home.Button.Login')}
										<i className="ms-2 bi bi-arrow-right"></i>
									</Nav.Link>
									{location.pathname === '/' && (
										<Nav.Link eventKey={2} href="#contact-from" className='scal primary-btn bg-black rounded-pill text-white p-4'>
											{t('Home.Button.BookDemo')}
										</Nav.Link>
									)}
								</aside>
							</Nav>
						</Navbar.Collapse>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	);
}
export default Header;
