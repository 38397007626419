import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import it from './it.json';

// the translations
const resources = {
	en: {
		translation: en
	},
	it: {
		translation: it
	}
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'it',
		// keySeparator: false, // we do not use keys in form messages.welcome
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
