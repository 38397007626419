import React from 'react';
import './App.css';
import { ThemeProvider } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LayoutComponent from './pages/layout';
import HomePage from './pages/homepage';
import TermsConditionPage from './pages/terms-condition';
import PrivacyPolicyPage from './pages/privacy-policy';


function App() {
	return (
		<ThemeProvider>
			<div className="App">
				<Router>
					<Routes>
						<Route path="/" element={<LayoutComponent><HomePage /></LayoutComponent>} />
						<Route path="/terms-conditions" element={<LayoutComponent><TermsConditionPage /></LayoutComponent>} />
						<Route path="/privacy-policy" element={<LayoutComponent><PrivacyPolicyPage /></LayoutComponent>} />
					</Routes>
				</Router>
			</div>
		</ThemeProvider >
	);
}
export default App;
